import React, { useEffect, useState } from "react";
import { baseUrl } from "../Services/Constants";
import { Tabs, Tab } from "@mui/material";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { AppSidebar } from "../Layouts/Sidebar";
import Header from "../Layouts/Header";
import axios from "axios";
import { column2 } from "../Services/ReportFunctions";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import { format, parseISO } from "date-fns";
import GridBackUpSection from "./GridBackUpSection";
import Box from "@mui/material/Box";
import ReportSection from "./ReportSection";
import AmsPage from "./AmsPage"
import ErrorLogSection from "./ErrorLogSection";
import ReProcessSection from "./ReProcessSection";
import ChecklistErrorLog from "./ChecklistErrorLog";

const Report = (props) => {
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [selectedOption, setSelectedOption] = useState("Report");
  const [selectOption, setSelectOption] = useState("Welcome To Exdion");
  const [activeTab, setActiveTab] = useState("table1");
  const [role, setRole] = useState(sessionStorage.getItem("role"));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {role != "report" ? (
        <p>no page found</p>
      ) : (
        <div>
          <div style={{ width: "100%" }}>
            <div className="app-container">
              <div style={{ padding: "5px", width: "95%" }}>
                {msgVisible && (
                  <div className="alert-container">
                    <div className={msgClass}>{msgText}</div>
                  </div>
                )}
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab
                      label="Reprocess section"
                      value="table1"
                      style={{ backgroundColor: "white !important" }}
                    />
                    <Tab
                      label="Error logs"
                      value="table2"
                      style={{ backgroundColor: "white !important" }}
                    />
                    <Tab
                      label="Report"
                      value="table3"
                      style={{ backgroundColor: "white !important" }}
                    />
                    <Tab
                      label="AMS Report"
                      value="table4"
                      style={{ backgroundColor: "white !important" }}
                    />
                    <Tab
                      label="BackUp Records"
                      value="table5"
                      style={{ backgroundColor: "white !important" }}
                    />
                    <Tab
                      label="Job checklist error"
                      value="table6"
                      style={{ backgroundColor: "white !important" }}
                    />
                  </Tabs>
                </Box>
                <div>
                  {activeTab == "table1" && <ReProcessSection />}
                  {activeTab == "table2" && <ErrorLogSection />}
                  {activeTab == "table3" && <ReportSection activeTab={activeTab} />}
                  {activeTab == "table4" && <AmsPage activeTab={activeTab}/>}
                  {activeTab == "table5" && <GridBackUpSection />}
                  {activeTab == "table6" && <ChecklistErrorLog />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Report;
