import React from "react";
import { Grid, Tooltip } from '@mui/material';
// import jwt_decode from "jwt-decode";

export const Header = ({ selectedOption, selectOption }) => {

    const token = sessionStorage.getItem("token");
    // const decode = jwt_decode(token || '');
    // const username = decode.UserName;


    // const first = username.charAt(0).toUpperCase();
    // const last = username.charAt(username.length - 1).toUpperCase();


    return (
        <div >
            <div>
                {/* <Grid lg={12} item container spacing={0.3} direction={"row"} >
                    <Grid lg={11} md={10} xs={9}>
                        <h2 className='props'>{selectedOption}</h2>
                        <h2 className='props'>{selectOption}</h2>
                    </Grid>
                </Grid> */}
                <h2 className='props'>{selectedOption}</h2>
            </div>
        </div>
    )
}

export default Header;