import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "./Layouts/AppLayout";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <div>
        <AppLayout />
      </div>
    </BrowserRouter>
  // </React.StrictMode>
);
