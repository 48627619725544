import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import Home from "../Components/Home";
import { Dashboard } from "../Components/DashBoard";
import { LandingPage } from "../Components/LandingPage";
import { baseData } from "../Services/Constants";
import AllJob from "../Components/AllJob";
import UnAuthorizedUser from "../Components/UnAuthorizedUser";
import Report from "../Components/Report";

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<LandingPage />} /> */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/job" element={<Home />} />
      <Route path="/AllJob" element={<AllJob />} />
      {sessionStorage.getItem("role") === "report" && (
        <Route path="/Report" element={<Report />} />
      )}
      <Route path="/UnAuthorizedUser" element={<UnAuthorizedUser />} />

      <Route path="/xlpage" element={<App baseData={baseData} />} />
      <Route path="/csrView" element={<App baseData={baseData} />} />
    </Routes>
  );
};

export default AppRoutes;
