import React, { useEffect, useState } from "react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import Tooltip from "@mui/material/Tooltip";
import { PrimaryButton } from "@fluentui/react";
import { ReportExcelExport } from "./ExportExcel/ReportExcelExport";

const GridBackUpDataTableView = (props) => {
  const [setId, setSetId] = useState(props?.data?.setId);
  const [JobId, setJobId] = useState(props?.data?.JobId);
  const [backUpData, SetBackUpData] = useState([]);
  const [columnKeys, SetColumnKeys] = useState({});
  const [canRender, SetCanRender] = useState(false);
  const [dataSetForExport, SetDataSetForExport] = useState([]);

  useEffect(() => {
    getData();
  }, [props?.data?.setId, props?.data?.JobId]);

  const getData = async () => {
    if (
      props?.data?.setId &&
      props?.data?.setId > 0 &&
      props?.data?.JobId &&
      props?.data?.JobId?.length > 0
    ) {
      let token = sessionStorage.getItem("");
      document.body.classList.add("loading-indicator");
      token = await processAndUpdateToken(token);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const response = await axios.get(
          baseUrl +
            "/api/ProcedureData/GetDataFromBackUpTables?jobId=" +
            props?.data?.JobId +
            "&setId=" +
            props?.data?.setId,
          {
            headers,
          }
        );
        document.body.classList.remove("loading-indicator");
        if (response.status !== 200) {
          return "failed";
        }
        if (response?.data) {
          const tables = [
            ...response?.data.map((e) => {
              return e?.tableName;
            }),
          ];
          SetBackUpData(response.data);
          // setTimeout(() => {
          //   transformDataForExport();
          // }, 500);
          const columnObject = {};
          tables.forEach((key) => {
            const column = structureColumns(key, response.data);
            columnObject[key] = column;
          });
          SetColumnKeys(columnObject);
          SetCanRender(true);
        } else {
          return "failed";
        }
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        return "failed";
      }
    }
  };

  const transformDataForExport = () => {
    if (backUpData && backUpData?.length > 0) {
      let transformedData = backUpData.map((item) => {
        if (item?.result && item.result?.length > 0) {
          const keys = Object.keys(item.result[0]);
          item.result = item.result.map((element) => {
            keys.forEach((key) => {
              if (element[key]) {
                element[key] = element[key]?.replace(/~~/g, "\r\n");
              }
            });
            return element;
          });
        }
        return { data: item?.result };
      });
      transformedData = transformedData.filter((f) => f?.data?.length > 0);
      const fileName = "backUpRecord-" + ` ${JobId}-${setId}`;
      ReportExcelExport(transformedData, fileName);
    }
  };

  const structureColumns = (key, data) => {
    let column = [];
    if (key) {
      console.log("key", key);
      const tableData = data.find((f) => f?.tableName == key)?.result;
      console.log("tableData", tableData);
      if (tableData && tableData?.length > 0) {
        const columnSet = Object.keys(tableData[0]).filter(
          (f) =>
            !["createdon", "updatedon", "columnid", "setid"]?.includes(
              f?.trim()?.toLowerCase()
            )
        );
        columnSet.forEach((col) => {
          column.push(getColumnObject(col));
        });
      }
    }
    return column;
  };

  const exportData = () => {
    if (dataSetForExport && dataSetForExport?.length > 0) {
      const fileName = "backUpRecord-" + ` ${JobId}-${setId}`;
      ReportExcelExport(dataSetForExport, fileName);
    }
  };

  const getColumnObject = (col) => {
    const colSpec = {
      name: col,
      // selector: (row, index) => (
      //   <Tooltip title={col == "Id" ? index : row[col]}>
      //     {col == "Id" ? index : row[col]}
      //   </Tooltip>
      // ),
      selector: (row) => {
        const htmlContent = row[col]
          ? row[col]?.replace(/~~/g, "<br />")
          : row[col];
        return <span dangerouslySetInnerHTML={{ __html: htmlContent }} />;
      },
      sortable: true,
      wrap: true,
      style: { fontSize: "11px !important" },
    };
    if (col?.trim()?.toLowerCase() == "id") {
      colSpec["width"] = "100px";
    } else if (col?.trim()?.toLowerCase() == "jobid") {
      colSpec["width"] = "200px";
    } else if (
      ["policy lob", "page number", "checklist questions", "lob"].includes(
        col?.trim()?.toLowerCase()
      )
    ) {
      colSpec["width"] = "200px";
    } else if (
      [
        "coverage_specifications_master",
        "coverage_specification_master",
        "observation",
      ].includes(col?.trim()?.toLowerCase())
    ) {
      colSpec["width"] = "300px";
    } else {
      colSpec["width"] = "400px";
    }
    return colSpec;
  };

  return (
    <div>
      <PrimaryButton
        text={
          <span style={{ color: "white", fontWeight: "bold" }}>Export</span>
        }
        onClick={() => {
          // console.log(dataSetForExport);
          transformDataForExport();
        }}
        style={{
          height: "32.1px",
          fontSize: "10px",
        }}
      />
      {backUpData &&
        canRender &&
        backUpData?.length > 0 &&
        backUpData?.map((item, index) => (
          <>
            <CommonDataTable
              key={index}
              column={columnKeys[item?.tableName]}
              data={item?.result || []}
            />
            <br />
          </>
        ))}
    </div>
  );
};

export default GridBackUpDataTableView;
