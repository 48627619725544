import React, { useEffect, useState } from "react";
import Header from "./Header";
import { AppSidebar } from "./Sidebar";
import AppRoutes from "./AppRoutes";
import { useLocation } from "react-router-dom";

const AppLayout = () => {
  const location = useLocation();

  const canRenderHeaderSection = () => {
    if (
      !(
        location.pathname === "/csrView" ||
        location.pathname === "/xlpage" ||
        location.pathname === "/UnAuthorizedUser"
      )
    ) {
      return true;
    }
    return false;
  };

  const canRenderSideBar = () => {
    if (
      location.pathname === "/csrView" ||
      location.pathname === "/UnAuthorizedUser"
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      {canRenderHeaderSection() && (
        <div className="header-container">
          <Header selectedOption={"Welcome To Exdion"} />
        </div>
      )}
      <div className="app-container">
        {canRenderSideBar() && (
          <div>
            <AppSidebar />
          </div>
        )}
        <div style={{ width: "100%" }}>
          <AppRoutes />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
